import { Icon, IconProps } from '@/components/Icon';
import { useClassName } from '@/styles/TextField';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';

export interface TextFieldIconProps extends ComponentProps<'svg'> {
  name?: IconProps['name'];
  colors?: ClassNameProp<'primary'>;
  size?: ClassNameProp<'default' | 'alternative'>;
  variant?: ClassNameProp<'default' | 'alternative'>;
}

export const TextFieldIconComponent: Component<TextFieldIconProps> = ({ ...props }) => {
  const className = useClassName('icon', props, { defaults: { colors: 'primary' } });

  return <Icon options={{ className: className }} {...props} />;
};
